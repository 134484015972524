//sizes
$horizontalIndent: 165px;
$roundButtonSize: 48px;

@mixin horizontal-screen-indent {
    padding-left: $horizontalIndent;
    padding-right: $horizontalIndent;
}

//colors
$red: #f16876;
$white: #ffffff;
$whiteBG: #fafafa;
$black: #2f2e2e;
$grayLight: #d3d3d3;
$gray: #81828c;
$green: #5ae288;
$greenLighter: #adf1c3;
$greenLight: #edfcf2;
$greenDark: #174943;

$blue: #3b58b8;
$blueHover: #486eec;
$blueActive: #1036b2;
$blueLight: #ebeef8;

//other
@mixin line10px {
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    border-radius: 1000px;
    background-color: $white;
    right: 2px;
}

@mixin arrow20px {
    width: 20px;
    height: 2px;
    background-color: $white;
    border-radius: 1000px;
    position: relative;

    &:before {
        @include line10px;
        bottom: -1px;
        transform: rotate(-45deg);
    }

    &:after {
        @include line10px;
        top: -1px;
        transform: rotate(45deg);
    }
}

@mixin fixedBottom {
    position: fixed;
    bottom: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

// fonts
// Avenir
@font-face {
    font-display: swap;
    font-family: 'AvenirNextCyrLight';
    src: url('./../public/fonts/AvenirNextCyr-Light.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'AvenirNextCyrNormal';
    src: url('./../public/fonts/AvenirNextCyr-Regular.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'AvenirNextCyrDemi';
    src: url('./../public/fonts/AvenirNextCyr-Demi.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'AvenirNextCyrBold';
    src: url('./../public/fonts/AvenirNextCyr-Bold.ttf') format('truetype');
}
// OpenSans
@font-face {
    font-display: swap;
    font-family: 'OpenSansLight';
    src: url('./../public/fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'OpenSansNormal';
    src: url('./../public/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'OpenSansDemi';
    src: url('./../public/fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'OpenSansBold';
    src: url('./../public/fonts/OpenSans-Bold.ttf') format('truetype');
}
// Lora
@font-face {
    font-display: swap;
    font-family: 'LoraBoldItalic';
    src: url('./../public/fonts/Lora-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'LoraItalic';
    src: url('./../public/fonts/Lora-Italic.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'LoraRegular';
    src: url('./../public/fonts/Lora-Regular.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'LoraMedium';
    src: url('./../public/fonts/Lora-Medium.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'LoraSemiBold';
    src: url('./../public/fonts/Lora-SemiBold.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'LoraSemiBoldItalic';
    src: url('./../public/fonts/Lora-SemiBoldItalic.ttf') format('truetype');
}
// Fredoka
@font-face {
    font-display: swap;
    font-family: 'FredokaLight';
    src: url('./../public/fonts/Fredoka-Light.ttf') format('truetype');
}
@font-face {
    font-display: swap;

    font-family: 'FredokaRegular';
    src: url('./../public/fonts/Fredoka-Regular.ttf') format('truetype');
}
@font-face {
    font-display: swap;

    font-family: 'FredokaMedium';
    src: url('./../public/fonts/Fredoka-Medium.ttf') format('truetype');
}
@font-face {
    font-display: swap;

    font-family: 'FredokaSemiBold';
    src: url('./../public/fonts/Fredoka-SemiBold.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'FredokaBold';
    src: url('./../public/fonts/Fredoka-Bold.ttf') format('truetype');
}
