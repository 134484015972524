@import 'styles/constants';

html {
    font-size: calc(10em / 16); // IE do not understand percents correctly, so this is the fix
}

body {
    font-size: 1.8rem;
}

html,
body {
    padding: 0;
    margin: 0;
    font-display: swap;
    font-family: Lora, Fredoka, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body > #hubspot-messages-iframe-container {
    visibility: hidden;
}

html,
body,
body > div:first-of-type,
a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

p {
    padding: 0px;
    margin: 0px;
}

.disable-overscroll {
    overscroll-behavior-y: none;
}

.wrapperQuote {
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.title {
    span {
        margin-left: 14px;
        font-size: 2.6rem;
    }

    font-family: LoraRegular;
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 130%;
    margin: 0;
    color: $greenDark;
    text-align: left;
    @media (min-width: 900px) {
        font-size: 4.8rem;
    }
}

h4.title {
    @media (min-width: 900px) {
        font-size: 2.8rem;
    }
}

.helperText {
  font-size: 1.4rem;
  color: $greenDark;
  text-align: left;
  font-family: FredokaRegular;
}

.subTitle,
.smallSubTitle {
    font-size: 1.6rem;
    color: $greenDark;
    text-align: left;
    font-family: FredokaRegular;

    @media (min-width: 900px) {
        font-size: 2.6rem;
    }
}
